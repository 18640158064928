<template>
  <div class="content">
    <PageHeader :title="'WhatsApp'" :subtitle="$t('sms.usage-report.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <!-- <card tabs>
            <template #header>
              <ul class="nav nav-tabs primary-tabs p-l-30 m-0">
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'SmsStats' }"
                    class="nav-link"
                    replace
                    >Stats</router-link
                  >
                </li>
              </ul>
            </template>
            <template #body>
              <div class="tab-content">
                <router-view />
              </div>
            </template>
          </card> -->
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs">
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'WhatsAppReport' }"
                    class="nav-link"
                    replace
                    ><span class="material-symbols-outlined">
                      insert_chart </span
                    >{{ $tc('app.report', 1) }}</router-link
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'WhatsAppStats' }"
                    class="nav-link"
                    replace
                    ><span class="material-symbols-outlined"> query_stats </span
                    >{{ $tc('app.stats', 2) }}</router-link
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'WhatsAppHistoryTab' }"
                    class="nav-link"
                    replace
                    ><span class="material-symbols-outlined"> history </span
                    >{{ $t('app.history') }}</router-link
                  >
                </li>
                <div class="opts_card">
                  <a
                    style="display: flex; align-items: center"
                    href="javascript:void(0)"
                    @click="reload"
                    ><span class="material-symbols-outlined title">
                      refresh
                    </span></a
                  >
                  <form class="form-inline calendar_form">
                    <date-range-picker
                      class="form-control"
                      :format="'YYYY-MM-DD'"
                      :options="options"
                      @change="changeDate"
                    />
                  </form>
                  <span class="material-icons title calendar_icon">
                    calendar_today
                  </span>
                  <i class="fas fa-file-export title"></i>
                </div>
              </ul>
            </div>
            <div class="card-body block-el p-0">
              <router-view
                :date-gte="form.date.gte"
                :date-lte="form.date.lte"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue';
import moment from 'moment';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import UsageService from '@/services/usage.service';
import Card from '@/components/Card';
import Tab from '@/directives/Tab';

export default {
  name: 'RCSDashboard',
  components: {
    PageHeader,
    // Card,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      fetched: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('01/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      usage: [],
      form: {
        category: 'sms',
        date: {
          gte: moment().format('YYYY-MM-01'),
          lte: moment().format('YYYY-MM-DD'),
        },
      },
    };
  },
  // Pai
  methods: {
    reload() {
      this.fetchUsage(1);
    },
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = date[1];
        Vue.set(this.form.date, 'gte', date[0]);
        Vue.set(this.form.date, 'lte', date[1]);
        const usage = this.$refs.refUsage;
        console.log('TESTE USAGE: ', usage);
        if (usage) {
          usage.fetchUsage();
        }
      }
      // this.fetchUsage();
    },
    fetchUsage() {
      this.fetched = false;
      UsageService.getAllUsage(this.form).then((response) => {
        this.usage = response;
        this.fetched = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}
</style>
